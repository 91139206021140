import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import deleteIcon from "../assets/icons/times-circle-light.svg";

class PhaseItem extends Component {
  render() {
    const { title, config, hours, index, id } = this.props;
    return (
      <div className="phase-item">
        <div className="description">
          <div className="active">
            <img
              alt="Delete"
              style={{
                width: "15px",
                height: "15px",
                opacity: "0.4",
                marginTop: "5px",
                marginRight: "5px"
              }}
              onClick={this.props.removeItemFromPhase(config.title, index)}
              src={deleteIcon}
            />
          </div>
          <TextField
            className="naked-input"
            id={id}
            defaultValue={title}
            onChange={this.props.editPhaseTitle(config.title, index)}
            margin="normal"
          />
        </div>
        <div className="hours">
          <input
            onChange={this.props.handleHourChange(config.title, index)}
            min={1}
            step={0.5}
            defaultValue={hours}
            type="number"
          />
        </div>
      </div>
    );
  }
}

export default PhaseItem;
