import React, { Component } from "react";
import FormatChooser from "./FormatChooser";
import SocialToolbar from "./SocialToolbar";
import SocialCanvas from "./SocialCanvas";

import { fire } from "../../firebase/firebase";

class Socialite extends Component {
  constructor() {
    super();
    this.state = {
      activeFormat: "instagram",
      backgroundImageUrl: "https://source.unsplash.com/random"
    };
  }

  handleChange = (name, value) => e => {
    this.setState({
      [name]: value
    });
  };

  //File uploader

  handleUploadSuccess = () => filename => {
    alert(filename);
    this.setState({
      backgroundImage: filename,
      progress: 100,
      isUploading: false
    });
    fire
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ backgroundImageUrl: url }));
  };

  handleUploadStart = () => e =>
    this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => e => this.setState({ progress });
  handleUploadError = error => e => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  render() {
    const { activeFormat, backgroundImageUrl } = this.state;
    return (
      <div className="socialite">
        <FormatChooser
          handleProgress={this.handleProgress}
          handleUploadStart={this.handleUploadStart}
          handleUploadError={this.handleUploadError}
          handleChange={this.handleChange}
          active={activeFormat}
        />
        <SocialCanvas bgImage={backgroundImageUrl} />
        <SocialToolbar
          handleProgress={this.handleProgress}
          handleUploadStart={this.handleUploadStart}
          handleUploadError={this.handleUploadError}
          handleUploadSuccess={this.handleUploadSuccess}
        />
      </div>
    );
  }
}

export default Socialite;
