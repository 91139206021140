import React, { Component } from "react";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";

class FormatChooser extends Component {
  render() {
    const { active, handleChange } = this.props;
    return (
      <div className="format-chooser">
        <img
          onClick={handleChange("activeFormat", "instagram")}
          className={active === "instagram" && "active"}
          src={instagram}
          alt="Instagram"
        />
        <img
          onClick={handleChange("activeFormat", "facebook")}
          className={active === "facebook" && "active"}
          src={facebook}
          alt="Facebook"
        />
      </div>
    );
  }
}

export default FormatChooser;
