import React, { Component } from "react";
import _ from "lodash";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class NewPhase extends Component {
  constructor() {
    super();
    this.state = {
      isSelectingOption: false
    };
  }

  renderPhase() {
    const { phases } = this.props;

    return (
      <div className="select-option">
        <h4>Add a New Phase</h4>
        <FormControl variant="outlined">
          <Select
            native
            value={this.state.age}
            onChange={this.props.addPhaseToConfig("phase")}
            input={
              <OutlinedInput
                name="age"
                labelWidth={200}
                id="outlined-age-native-simple"
              />
            }>
            <option value="Select Phase Type">Select Phase Type</option>
            {_.map(phases, (phase, i) => {
              if (phase.title !== "Project Management") {
                return (
                  <option key={i} value={phase.title}>
                    {phase.title}
                  </option>
                );
              }
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
  render() {
    return <div className="new-phase">{this.renderPhase()}</div>;
  }
}

export default NewPhase;
