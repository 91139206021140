import React, { Component } from "react";
import { Link } from "react-router-dom";
class LoginPrompt extends Component {
  render() {
    return (
      <div className="center-content">
        <div style={{ textAlign: "center", margin: "0 auto" }}>
          <p>You must be signed in to access the editor.</p>{" "}
          <Link className="button is-primary" to="/">
            Sign In / Sign Up
          </Link>
        </div>
      </div>
    );
  }
}

export default LoginPrompt;
