import React, { Component } from "react";

export default class Search extends Component {
  componentDidMount() {
    this.props.handleChange.bind(this);
  }
  render() {
    return (
      <div className="search-bar">
        <input
          type="text"
          id="standard-name"
          placeholder="Search by project title"
          value={this.props.search}
          onChange={this.props.handleChange}
          margin="normal"
        />
        <i className="far fa-search" />
      </div>
    );
  }
}
