import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyBi1zDXSm59K5MWiUrZiQ21En3ArLmawyo",
  authDomain: "ilo-hour-estimator.firebaseapp.com",
  databaseURL: "https://ilo-hour-estimator.firebaseio.com",
  projectId: "ilo-hour-estimator",
  storageBucket: "ilo-hour-estimator.appspot.com",
  messagingSenderId: "97094741600"
};

const fire = firebase.initializeApp(config);
export { fire };

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();

export { auth };

export const provider = new firebase.auth.GoogleAuthProvider();
