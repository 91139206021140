import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import _ from "lodash";
import Editor from "./components/Editor";
import Library from "./components/Library";
import ProjectDefaults from "./components/ProjectDefaults";
import NavBar from "./components/NavBar";
import Socialite from "./components/Socialite/Socialite";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import createHistory from "history/createBrowserHistory";
import { auth, fire } from "./firebase/firebase";
import Login from "./components/auth/Login";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//Google Analytics//
const history = createHistory();

class App extends Component {
  constructor() {
    super();
    this.state = {
      admin: null,
      isNewSave: null,
      displayName: "",
      snackbar: false,
      config: {
        phases: [],
        buffer: 0
      },
      phases: null
    };
  }

  isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  componentDidMount() {
    const url = window.location.pathname;
    let idx = url.lastIndexOf("/");
    const saveId = url.substring(idx + 1).trim();

    //Authenticate user

    auth.onAuthStateChanged(authUser => {
      authUser
        ? fire
            .database()
            .ref("/users/" + authUser.uid)
            .once("value")
            .then(snapshot => {
              this.setState(
                {
                  admin: true,
                  displayName: snapshot.val() && snapshot.val().displayName,
                  saves: snapshot.val() && snapshot.val().savedData,
                  authUser: authUser,
                  uid: authUser.uid
                },
                () => this.getAllSaves(saveId)
              );
            })
        : this.setState({ authUser: null });

      fire
        .database()
        .ref("/phases")
        .once("value")
        .then(snapshot => {
          this.setState(
            {
              phases: snapshot.val()
            },
            () => this.setInitialPhases(saveId)
          );
        });
    });
  }

  //Set Project Mgmt and Asset Creation as initial phases
  setInitialPhases = saveId => {
    if (!this.isNumeric(saveId)) {
      let initialPhases = _.filter(this.state.phases, (phase, i) => {
        return phase.title === "Project Management";
      });

      this.setState(
        {
          config: {
            uid: this.state.uid,
            messages: [],
            buffer: 0,
            phases: [...initialPhases]
          }
        },
        () => this.getAllSaves()
      );
    } else {
      this.getAllSaves();
    }
  };

  //Reset App

  resetAll = () => e => {
    window.location.href = "/editor";

    this.setState(
      {
        ...this.state,
        isNewSave: false
      },
      () => this.setInitialPhases()
    );
  };

  changeProjectBuffer = () => e => {
    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        buffer: e.target.value
      }
    });
  };

  retrieveSaveData = saveId => {
    if (this.isNumeric(saveId)) {
      const { allSaves } = this.state;
      this.setState({
        config: {
          ...allSaves[saveId].config,
          existingSave: true
        }
      });
    }
  };

  //handle logout

  logout = () => e => {
    auth.signOut().then(() => {
      this.setState(
        {
          user: null
        },
        () => (window.location.href = "/")
      );
    });
  };

  //get all saves
  getAllSaves = saveId => {
    fire
      .database()
      .ref("/savedData/")
      .once("value")
      .then(snapshot => {
        this.setState(
          {
            allSaves: snapshot.val()
          },
          () => this.retrieveSaveData(saveId)
        );
      });
  };

  //Triggers on Save

  saveCallback = id => {
    this.setState(
      {
        isNewSave: true,
        snackbar: true,
        config: {
          ...this.state.config,
          saveLoad: true,
          existingSave: true,
          id: id
        }
      },
      () => this.delayClose()
    );
  };

  saveTitle = () => e => {
    this.setState({
      config: {
        ...this.state.config,
        projectTitle: e.target.value
      }
    });
  };

  submitMessage = newMessage => e => {
    const { config, displayName } = this.state;

    let messages = [];
    if (config.messages) {
      messages = config.messages;
    }
    let message = { author: displayName, content: newMessage };

    messages.push(message);

    fire
      .database()
      .ref("/savedData/" + config.id + "/config/")
      .update({
        messages: messages
      })
      .then(
        this.setState({
          ...this.state,
          config: {
            ...this.state.config,
            messages: messages
          }
        })
      );
  };

  //Trigger snackbar callback w/ delay

  delayClose = () => {
    setTimeout(
      function() {
        this.setState({ snackbar: false });
      }.bind(this),
      3000
    );
  };

  //Save all Data

  saveForm = name => event => {
    const uniqueId = +new Date(),
      url = window.location.pathname;

    let idx = url.lastIndexOf("/");
    event.preventDefault();
    const saveId = url.substring(idx + 1).trim();

    if (url === "/editor") {
      window.history.pushState(
        "object or string",
        "Title",
        "/editor/" + uniqueId
      );

      fire
        .database()
        .ref("/users/" + this.state.uid)
        .update({
          hasSave: true,
          messages: []
        });

      fire
        .database()
        .ref("/savedData/" + uniqueId)
        .set(
          {
            config: { ...this.state.config, id: uniqueId }
          },
          () => this.saveCallback(uniqueId)
        );
    } else {
      fire
        .database()
        .ref("/savedData/" + saveId)
        .update(
          {
            config: { ...this.state.config, id: saveId }
          },
          () => this.saveCallback()
        );
    }
  };

  // Add Items to Phase

  addItemToPhase = name => e => {
    const { config } = this.state,
      uniqueId = +new Date(),
      index = _.findIndex(config.phases, ["title", name]),
      input = { optionTitle: "Enter deliverable", hours: 1, id: uniqueId };
    let phase = [...this.state.config.phases];

    phase[index].defaultOptions.push(input);
    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        phases: [...phase]
      }
    });
  };

  editPhaseTitle = (name, optionIndex) => e => {
    const { config } = this.state,
      allPhases = config.phases,
      currentPhases = _.filter(allPhases, phase => {
        if (phase.title === name) {
          return phase;
        }
      });
    //eslint-disable-next-line
    let options = _.filter(currentPhases[0].defaultOptions, (phase, i) => {
      if (i === optionIndex) {
        phase.optionTitle = e.target.value;
      }
    });

    this.setState({
      config: {
        ...this.state.config,
        ...allPhases
      }
    });
  };

  editPhaseRate = name => e => {
    const { config } = this.state,
      allPhases = config.phases,
      currentPhase = _.filter(allPhases, { title: name });
    currentPhase[0].rate = e.target.value;

    this.setState({
      config: {
        ...this.state.config,
        ...allPhases
      }
    });
  };

  handleHourChange = (name, optionIndex) => e => {
    const { config } = this.state,
      allPhases = config.phases,
      //Get Phase Index
      //eslint-disable-next-line
      phaseIndex = _.findIndex(allPhases, { title: name }),
      //Get Target Phase as Object
      currentPhases = _.filter(allPhases, phase => {
        if (phase.title === name) {
          return phase;
        }
      });

    //Replace Hoursin Target Phase
    //eslint-disable-next-line
    let options = _.filter(currentPhases[0].defaultOptions, (phase, i) => {
      if (i === optionIndex) {
        phase.hours = parseFloat(e.target.value);
      }
    });

    //Update State

    this.setState({
      config: {
        ...this.state.config,
        ...allPhases
      }
    });
  };

  //duplicate estimate and save to firebase

  duplicateSave = id => e => {
    const uniqueId = +new Date();

    let saves = this.state.allSaves;
    let targetSave = _.filter(saves, (save, i) => {
      if (save.config.id === id) {
        return save;
      }
    });

    const originalTitle = targetSave[0].config.projectTitle;

    targetSave[0].config.projectTitle = originalTitle + " COPY";
    targetSave[0].config.id = uniqueId;

    fire
      .database()
      .ref("/savedData/" + uniqueId)
      .set(
        {
          ...targetSave[0]
        },
        () => {
          window.location.href = "/editor/" + uniqueId;
        }
      );
  };

  //Remove save from firebase

  restoreSave = id => e => {
    let saves = this.state.allSaves;

    fire
      .database()
      .ref("/savedData/" + id)
      .update({
        archived: false
      })
      .then(this.handleHide(id));
  };

  removeSave = id => e => {
    let saves = this.state.allSaves;

    fire
      .database()
      .ref("/savedData/" + id)
      .update({
        archived: true
      })
      .then(
        this.setState(
          {
            allSaves: _.filter(saves, (save, i) => {
              if (save.id !== id) {
                return save;
              }
            })
          },
          this.handleHide(id)
        )
      );
  };

  handleHide = name => e => {
    console.log("hidden");
    this.setState({
      ...this.state,
      [name]: true
    });
  };

  //Removes Items from Phase

  removeItemFromPhase = (name, optionIndex) => e => {
    const { config } = this.state,
      allPhases = config.phases,
      phaseIndex = _.findIndex(allPhases, { title: name });

    let phases = [...this.state.config.phases];

    phases[phaseIndex].defaultOptions = _.filter(
      config.phases[phaseIndex].defaultOptions,
      (phase, i) => {
        if (i !== optionIndex) {
          return phase;
        }
      }
    );

    this.setState({
      config: {
        buffer: config.buffer,
        phases,
        projectTitle: config.projectTitle
      }
    });
  };

  addPhaseToConfig = name => e => {
    const { phases } = this.state,
      addedPhase = _.filter(phases, ["title", e.target.value]);
    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        phases: [...this.state.config.phases, ...addedPhase]
      }
    });
  };

  removePhaseFromConfig = name => e => {
    const { config } = this.state,
      phaseIndex = _.findIndex(config.phases, { title: name }),
      updatedPhases = _.filter(config.phases, (x, i) => i !== phaseIndex);

    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        phases: [...updatedPhases]
      }
    });
  };

  handleChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  render() {
    const { config, phases, uid, admin } = this.state;

    return (
      <Router history={history}>
        <div className="main">
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            open={this.state.snackbar}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">Everything Saved</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
          <NavBar
            projectTitle={config.projectTitle}
            logout={this.logout}
            saveTitle={this.saveTitle}
            uid={uid}
            resetAll={this.resetAll}
            saveForm={this.saveForm}
          />
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <Login
                          login={this.login}
                          user={this.state.user}
                          location={props.location}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/library"
                      render={props => (
                        <Library
                          state={this.state}
                          duplicateSave={this.duplicateSave}
                          admin={admin}
                          isNewSave={this.state.isNewSave}
                          remove={this.removeSave}
                          restore={this.restoreSave}
                          saves={this.state.allSaves}
                          user={this.state.user}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/socialite"
                      render={props => <Socialite {...props} />}
                    />
                    <Route
                      exact
                      path="/defaults"
                      render={props => (
                        <ProjectDefaults
                          phases={phases}
                          user={this.state.user}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/editor"
                      render={props => (
                        <Editor
                          submitMessage={this.submitMessage}
                          displayName={this.state.displayName}
                          admin={this.state.admin}
                          editPhaseRate={this.editPhaseRate}
                          editPhaseTitle={this.editPhaseTitle}
                          changeProjectBuffer={this.changeProjectBuffer}
                          removeItemFromPhase={this.removeItemFromPhase}
                          addItemToPhase={this.addItemToPhase}
                          handleHourChange={this.handleHourChange}
                          phases={phases}
                          config={config}
                          removePhaseFromConfig={this.removePhaseFromConfig}
                          addPhaseToConfig={this.addPhaseToConfig}
                          login={this.login}
                          user={this.state.user}
                          location={props.location}
                          {...props}
                        />
                      )}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </div>
      </Router>
    );
  }
}
export default App;
