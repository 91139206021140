import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { auth } from "../../firebase/";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

export default class LogForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  onSubmit = event => {
    const { email, password } = this.state;
    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        window.location.href = "/editor";
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  renderWelcomeText() {
    if (this.props.isNew) {
      return (
        <div className="welcome-login">
          <h2>Thanks for signing up</h2>
          <p>Please login below</p>
        </div>
      );
    }
  }

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <div>
        {this.renderWelcomeText()}
        <form onSubmit={this.onSubmit}>
          <FormControl fullWidth>
            <TextField
              id="email"
              label="Email"
              placeholder="Enter Your Email"
              onChange={e => this.setState({ email: e.target.value })}
            />
            <FormControl style={{ marginTop: "20px" }}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                placeholder="Enter a password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <input
              disabled={isInvalid}
              style={{ marginTop: "20px", width: "100%" }}
              type="submit"
              className="is-primary button"
              value="Log In"
            />
          </FormControl>
          {error && <p className="form-error">{error.message}</p>}
        </form>
      </div>
    );
  }
}
