import React, { Component } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import pen from "../../assets/icons/pen-square-light.svg";

class SocialCanvas extends Component {
  constructor() {
    super();
    this.state = {
      scale: 0.5,
      translation: { x: 0, y: 0 }
    };
  }
  render() {
    const { bgImage } = this.props,
      { scale, translation } = this.state;
    return (
      <div
        style={{
          width: "520px",
          height: "520px",
          overflow: "hidden"
        }}
      >
        <MapInteractionCSS style={{ position: "absolute" }}>
          <img src={pen} />
        </MapInteractionCSS>
        <MapInteractionCSS
          scale={scale}
          translation={translation}
          onChange={({ scale, translation }) =>
            this.setState({ scale, translation })
          }
        >
          <div>
            <img src={bgImage} alt="canvas" />
          </div>
        </MapInteractionCSS>
      </div>
    );
  }
}

export default SocialCanvas;
