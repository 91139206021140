import React, { Component, Fragment } from "react";
import PhaseItem from "./PhaseItem";
import trash from "../assets/icons/trash-alt.svg";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";

class Phase extends Component {
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  generateKey = pre => {
    return `${pre}_${new Date().getTime()}`;
  };
  render() {
    const {
      config,
      handleHourChange,
      removeItemFromPhase,
      editPhaseTitle
    } = this.props;
    const options = config.defaultOptions.length;
    if (config) {
      return (
        <CSSTransition classNames="fade" timeout={500}>
          <div className="phase-container">
            <div className="info-box">
              <div className="phase-title">{config.title}</div>
              <div className="icon-bar">
                {config.title !== "Project Management" && (
                  <img
                    onClick={this.props.removePhaseFromConfig(config.title)}
                    src={trash}
                    alt="Trash"
                  />
                )}
                <div className="rate-amount">
                  $
                  <input
                    onChange={this.props.editPhaseRate(config.title)}
                    min={1}
                    defaultValue={config.rate}
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="phase-box">
              <div className="top-labels">
                <div>Deliverable</div>
                <div>Hrs.</div>
              </div>
              <div className="phase-list">
                {_.map(config.defaultOptions, (option, i) => {
                  const id = i + Math.random();
                  const length =
                    Object.values(config.defaultOptions).length - 1;
                  return (
                    <Fragment key={option.id}>
                      <PhaseItem
                        id={id}
                        editPhaseTitle={editPhaseTitle}
                        index={i}
                        removeItemFromPhase={removeItemFromPhase}
                        handleChange={this.handleChange}
                        title={option.optionTitle}
                        config={config}
                        handleHourChange={handleHourChange}
                        hours={option.hours}
                      />
                      {length === i && (
                        <div
                          onClick={this.props.addItemToPhase(config.title)}
                          className="new-item">
                          + Add New Item
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              {options === 0 && (
                <div
                  onClick={this.props.addItemToPhase(config.title)}
                  className="new-item">
                  + Add New Item
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      );
    } else {
      return null;
    }
  }
}

export default Phase;
