import React, { Component, Fragment } from "react";
import logo from "../assets/logo.svg";
import save from "../assets/icons/save.svg";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import menu from "../assets/icons/menu.svg";
import Menu from "./Menu";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      uid: null,
      left: false
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.uid !== state.uid) {
      return {
        uid: props.uid
      };
    } else return false;
  };

  toggleDrawer = (side, open) => () => {
    console.log("true");
    this.setState({
      [side]: open
    });
  };

  render() {
    const { logout } = this.props;
    const url = window.location.pathname;
    const isEditor = url.includes("editor");

    return (
      <Fragment>
        <Menu
          logout={logout}
          left={this.state.left}
          toggleDrawer={this.toggleDrawer}
        />
        <header className="nav">
          <div className="flex-container">
            <div className="left-bar">
              <div className="logo">
                <Link to="/editor">
                  <img src={logo} alt="ilo" />
                </Link>
              </div>
              {isEditor && (
                <div className="project-info">
                  <TextField
                    id="standard-name"
                    placeholder={"Enter Project Title"}
                    value={this.props.projectTitle}
                    onChange={this.props.saveTitle()}
                    margin="normal"
                  />
                </div>
              )}
            </div>
            <div className="toolbar">
              {isEditor && (
                <Fragment>
                  <img
                    className="save-icon"
                    onClick={this.props.saveForm()}
                    src={save}
                    alt="save"
                  />
                </Fragment>
              )}
              <div className="new-project" onClick={this.props.resetAll()}>
                <span>+</span> New Estimate
              </div>
              <img
                alt="Menu"
                onClick={() => this.setState({ left: true })}
                src={menu}
                className="menu-icon"
              />
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

export default NavBar;
