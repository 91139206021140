import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import send from "../assets/icons/send.svg";

export default class Messages extends Component {
  constructor() {
    super();
    this.state = {
      newMessage: ""
    };
  }

  handleChange = name => event => {
    this.setState({
      newMessage: event.target.value
    });
  };

  renderAllMessages() {
    const { config } = this.props;

    return (
      <div className="current-messages">
        {_.map(config.messages, message => {
          return (
            <div className="previous-comment">
              <div className="content">
                <p>{message.content}</p>
              </div>
              <div className="author">{message.author}</div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { newMessage } = this.state;
    const { config, submitMessage } = this.props;
    if (config.existingSave) {
      return (
        <div className="messages">
          <h2>Comments</h2>
          {this.renderAllMessages()}
          <TextField
            id="outlined-multiline-flexible"
            label="Add New Comment"
            multiline
            rowsMax="4"
            value={newMessage}
            onChange={this.handleChange("multiline")}
            margin="normal"
            variant="outlined"
          />
          <div onClick={submitMessage(newMessage)} className="btn-submit">
            Submit Comment
            <img
              style={{ width: "15px", marginLeft: "10px" }}
              src={send}
              alt="Send"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="pre-save">Save this estimate to enable comments.</div>
      );
    }
  }
}
