import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { fire } from "../../firebase/firebase";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { auth } from "../../firebase/";

const INITIAL_STATE = {
  showPassword: false,
  checked: false,
  fname: "",
  lname: "",
  email: "",
  company: "",
  password: "",
  error: null,
  listMembers: ""
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

export default class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  onSubmit = e => {
    e.preventDefault();

    const { email, password } = this.state,
      user = this.state;

    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        fire
          .database()
          .ref("users/" + authUser.user.uid)
          .set({
            company: user.company,
            email: user.email,
            displayName: user.fname + " " + user.lname,
            uid: authUser.user.uid
          });
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    this.props.setAccount();
  };

  render() {
    const { name, email, password, error } = this.state;

    const isInvalid =
      password === "" || password.length < 6 || email === "" || name === "";

    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          style={{ marginRight: "5%", width: "45%" }}
          id="lname"
          name="FNAME"
          label="First Name"
          placeholder="First Name"
          margin="normal"
          onChange={e => this.setState({ fname: e.target.value })}
        />
        <TextField
          style={{ width: "50%" }}
          id="fname"
          name="LNAME"
          label="Last Name"
          placeholder="Last Name"
          margin="normal"
          onChange={e => this.setState({ lname: e.target.value })}
        />
        <FormControl fullWidth>
          <TextField
            id="email"
            name="EMAIL"
            label="Email"
            placeholder="Enter Your Email"
            margin="normal"
            onChange={e => this.setState({ email: e.target.value })}
          />

          <TextField
            id="company"
            name="COMPANY"
            label="Company"
            placeholder="Comany Name"
            margin="normal"
            onChange={e => this.setState({ company: e.target.value })}
          />

          <FormControl style={{ marginTop: "20px" }}>
            <InputLabel htmlFor="adornment-password">Password</InputLabel>
            <Input
              id="adornment-password"
              placeholder="Enter a password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <div className="columns" style={{ paddingTop: "30px" }}>
            <div className="column is-fullwidth">
              <button
                disabled={isInvalid}
                className="button is-primary is-fullwidth"
                type="submit"
              >
                Sign Up
              </button>
              {error && <p className="form-error">{error.message}</p>}
            </div>
          </div>
        </FormControl>
      </form>
    );
  }
}
