import React, { Component } from "react";
import _ from "lodash";
import Search from "./Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import deleteIcon from "../assets/icons/times-circle-light.svg";
import restoreIcon from "../assets/icons/restore.svg";
import copy from "../assets/icons/copy.svg";
import { fire } from "../firebase/firebase";
import LoginPrompt from "./LoginPrompt";

class Library extends Component {
  constructor() {
    super();
    this.state = {
      searchInput: "",
      savesLoaded: false,
      saves: [],
      currentView: "active"
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isNewSave && !prevState.savesLoaded) {
      this.getAllSaves();
    }
  }

  componentDidMount() {
    this.getAllSaves();
  }

  getAllSaves() {
    const { currentView } = this.state;
    fire
      .database()
      .ref("/savedData/")
      .once("value")
      .then(snapshot => {
        let data = [];
        let dataArchived = [];
        // eslint-disable-next-line
        const map = _.map(snapshot.val(), allSaves => {
          const { config } = allSaves,
            title = config.projectTitle,
            id = config.id,
            archived = allSaves.archived;
          if (!archived) {
            data.push({ title, id });
          } else {
            dataArchived.push({ title, id });
          }
        });

        if (data.length > 0) {
          this.setState({
            saves: data.reverse(),
            archivedSaves: dataArchived.reverse(),
            savesLoaded: true
          });
        }
      });
  }

  handleChange = event => {
    this.setState({
      searchInput: event.target.value
    });
  };

  renderSaveBar() {
    return (
      <Search
        handleChange={this.handleChange}
        search={this.state.searchInput}
      />
    );
  }

  renderSaves() {
    const { superAdmin } = this.props;
    const { currentView } = this.state;
    const saveEntry = this.state.saves;
    const saveArchive = this.state.archivedSaves;
    const search = this.state.searchInput.toLowerCase();

    let searchResults = [];

    if (currentView === "active") {
      searchResults = _.filter(saveEntry, function(o) {
        let title = "";
        if (o.title !== undefined) {
          title = o.title.toLowerCase();
        }
        const data = title;

        return _.includes(data, search);
      });
    } else {
      searchResults = _.filter(saveArchive, function(o) {
        let title = "";
        if (o.title !== undefined) {
          title = o.title.toLowerCase();
        }
        const data = title;

        return _.includes(data, search);
      });
    }

    if (Object.keys(saveEntry).length === 0) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    } else if (searchResults.length > 0) {
      return _.map(searchResults, (saves, i) => {
        // console.log(saves);
        return (
          <div
            className={
              this.props.state[saves.id]
                ? "save-database fade-left"
                : "save-database"
            }
            key={i}
            style={{ marginBottom: "20px" }}
          >
            <div className="flex-container">
              <div className="save-box">
                <div className="columns">
                  <div className="column">
                    {saves.title && (
                      <div className="save-file">{saves.title}</div>
                    )}

                    {!saves.title && <div className="save-file">Untitled</div>}
                  </div>
                </div>
                <div className="save-ui">
                  <a
                    className="button is-primary"
                    style={{ marginRight: "10px" }}
                    href={"/editor/" + saves.id}
                  >
                    Load
                  </a>
                  <a onClick={this.props.duplicateSave(saves.id)}>
                    <img
                      src={copy}
                      style={{
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                        opacity: "0.4",
                        marginTop: "5px",
                        marginRight: "15px"
                      }}
                      alt="Copy Estimate"
                    />
                  </a>

                  <div
                    className="delete"
                    onClick={
                      currentView === "active"
                        ? this.props.remove(saves.id)
                        : this.props.restore(saves.id)
                    }
                  >
                    <img
                      src={currentView === "active" ? deleteIcon : restoreIcon}
                      style={{
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                        opacity: "0.4",
                        marginTop: "5px",
                        marginRight: "5px"
                      }}
                      alt="Delete"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <p>There are no results for your search</p>;
    }
  }

  render() {
    const { saves, admin } = this.props;
    const { currentView } = this.state;
    if (admin) {
      if (saves) {
        return (
          <div
            className="library-container"
            style={{ paddingTop: "50px", minHeight: "100vh" }}
          >
            <div className="product-settings">
              <h2>Estimate Archive</h2>
              <p>View and search all estimates</p>
            </div>

            {this.renderSaveBar()}
            <div className="sorter">
              <h4
                onClick={() => this.setState({ currentView: "active" })}
                className={currentView === "active" ? "active" : ""}
              >
                Active
              </h4>
              <h4
                onClick={() => this.setState({ currentView: "archived" })}
                className={currentView === "archived" ? "active" : ""}
              >
                Archived
              </h4>
            </div>
            {Object.keys(this.props.saves).length !== 0 && (
              <div className="columns top-sort">
                <div className="column">Estimate Name</div>
              </div>
            )}
            {this.renderSaves()}
          </div>
        );
      } else {
        return <div className="center-content">There are no saves</div>;
      }
    } else {
      return <LoginPrompt />;
    }
  }
}

export default Library;
