import React, { Component } from "react";
import AuthForm from "./AuthForm";

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      history: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section>
        <div className="login-container">
          <AuthForm location={this.props.location} login={this.props.login} />
        </div>
      </section>
    );
  }
}
