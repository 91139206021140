import React, { Component } from "react";
import library from "../assets/icons/library.svg";
import logout from "../assets/icons/logout.svg";
import { Link } from "react-router-dom";
import pen from "../assets/icons/pen-square-light.svg";
import socialite from "../assets/icons/socialite.svg";
import calculator from "../assets/icons/calculator.svg";
import Drawer from "@material-ui/core/Drawer";

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      left: false
    };
  }

  render() {
    return (
      <Drawer
        open={this.props.left}
        onClose={this.props.toggleDrawer("left", false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.props.toggleDrawer("left", false)}
          onKeyDown={this.props.toggleDrawer("left", false)}
        >
          <div>
            <div className="sidebar-menu">
              <div className="menu-item">
                <div className="menu-container">
                  <span>
                    <img src={calculator} alt="New Estimate" />
                  </span>
                  <Link to="/editor">Hour Estimator</Link>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-container">
                  <span>
                    <img src={library} alt="All Estimates" />
                  </span>
                  <Link to="/library">View All Estimates</Link>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-container">
                  <span>
                    <img src={pen} alt="Edit Defaults" />
                  </span>
                  <Link to="/defaults">Project Defaults</Link>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-container">
                  <span>
                    <img src={socialite} alt="Socialite" />
                  </span>
                  <Link to="/socialite">Socialite</Link>
                </div>
              </div>
              <div className="menu-item">
                <div className="menu-container" onClick={this.props.logout()}>
                  <span>
                    <img src={logout} alt="Logout" />
                  </span>
                  <Link to="/">Logout</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default Menu;
