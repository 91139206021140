import React, { Component } from "react";
import Phase from "./Phase";
import NewPhase from "./NewPhase";
import Messages from "./Messages";
import _ from "lodash";

class Workspace extends Component {
  renderPhases() {
    const {
      config,
      addItemToPhase,
      removePhaseFromConfig,
      removeItemFromPhase,
      handleHourChange,
      editPhaseRate,
      editPhaseTitle
    } = this.props;
    if (config.phases !== null) {
      return _.map(config.phases, (phase, i) => {
        return (
          <Phase
            key={i}
            editPhaseRate={editPhaseRate}
            editPhaseTitle={editPhaseTitle}
            addItemToPhase={addItemToPhase}
            removeItemFromPhase={removeItemFromPhase}
            handleHourChange={handleHourChange}
            removePhaseFromConfig={removePhaseFromConfig}
            config={phase}
          />
        );
      });
    }
  }
  render() {
    const {
      phases,
      addPhaseToConfig,
      config,
      displayName,
      submitMessage
    } = this.props;
    return (
      <div className="workspace">
        <div className="flex-container">{this.renderPhases()}</div>
        <NewPhase addPhaseToConfig={addPhaseToConfig} phases={phases} />
        <Messages
          displayName={displayName}
          config={config}
          submitMessage={submitMessage}
        />
      </div>
    );
  }
}

export default Workspace;
