import React, { Component, Fragment } from "react";
import _ from "lodash";
import { fire } from "../firebase/firebase";
import PhaseItem from "./PhaseItem";

class ProjectDefaults extends Component {
  constructor() {
    super();
    this.state = {
      phases: [],
      activePhase: 0
    };
  }
  componentDidMount() {
    fire
      .database()
      .ref("/phases")
      .once("value")
      .then(snapshot => {
        this.setState({
          phases: snapshot.val()
        });
      });
  }

  saveDefaults = () => e => {
    fire
      .database()
      .ref("/phases")
      .update({
        ...this.state.phases
      });
  };

  editPhaseTitle = (name, optionIndex) => e => {
    const allPhases = this.state.phases,
      phaseIndex = _.findIndex(allPhases, { title: name }),
      currentPhases = _.filter(allPhases, phase => {
        if (phase.title === name) {
          return phase;
        }
      });

    let options = _.filter(currentPhases[0].defaultOptions, (phase, i) => {
      if (i === optionIndex) {
        phase.optionTitle = e.target.value;
      }
    });

    this.setState({
      ...allPhases
    });
  };

  renderProjectList() {
    const { phases, activePhase } = this.state;
    return _.map(phases, (phase, i) => {
      return (
        <li
          className={i === activePhase && "active"}
          onClick={() => this.setState({ activePhase: i })}
          key={i + Math.random()}
        >
          {phase.title}
        </li>
      );
    });
  }

  //Removes Items from Phase

  removeItemFromPhase = (name, optionIndex) => e => {
    const { phases } = this.state,
      phaseIndex = _.findIndex(phases, { title: name });

    phases[phaseIndex].defaultOptions = _.filter(
      phases[phaseIndex].defaultOptions,
      (phase, i) => {
        if (i !== optionIndex) {
          return phase;
        }
      }
    );

    this.setState({
      phases
    });
  };

  // Add Items to Phase

  addItemToPhase = name => e => {
    console.log(name);
    const { phases } = this.state,
      index = _.findIndex(phases, ["title", name]),
      input = { optionTitle: "Enter deliverable", hours: 1 };
    let phase = [...phases];

    console.log(index);

    phase[index].defaultOptions.push(input);
    this.setState({
      phases: [...phase]
    });
  };

  handleHourChange = (name, optionIndex) => e => {
    const { phases } = this.state,
      //Get Phase Index
      //eslint-disable-next-line
      phaseIndex = _.findIndex(phases, { title: name }),
      //Get Target Phase as Object
      currentPhases = _.filter(phases, phase => {
        if (phase.title === name) {
          return phase;
        }
      });

    //Replace Hoursin Target Phase
    //eslint-disable-next-line
    let options = _.filter(currentPhases[0].defaultOptions, (phase, i) => {
      if (i === optionIndex) {
        phase.hours = parseInt(e.target.value);
      }
    });

    //Update State
    this.setState({
      ...phases
    });
  };

  renderDefaultEditor() {
    const { phases, activePhase } = this.state;
    return _.map(phases, (phase, i) => {
      return _.map(phase.defaultOptions, (option, o) => {
        if (i === activePhase) {
          const length = phase.defaultOptions.length - 1;
          return (
            <Fragment key={o.id}>
              <PhaseItem
                editPhaseTitle={this.editPhaseTitle}
                index={o}
                removeItemFromPhase={this.removeItemFromPhase}
                handleChange={this.handleChange}
                title={option.optionTitle}
                config={phase}
                handleHourChange={this.handleHourChange}
                hours={option.hours}
              />
              {length === o && (
                <div
                  onClick={this.addItemToPhase(phase.title)}
                  className="new-item"
                >
                  + Add New Deliverable
                </div>
              )}
            </Fragment>
          );
        }
      });
    });
  }
  render() {
    return (
      <div className="project-defaults">
        <div className="flex-container">
          <div className="project-list">
            {this.renderProjectList()}
            <div className="button is-primary" onClick={this.saveDefaults()}>
              Save Defaults
            </div>
          </div>
          <div className="default-editor">{this.renderDefaultEditor()}</div>
        </div>
      </div>
    );
  }
}

export default ProjectDefaults;
