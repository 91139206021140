import React, { Component } from "react";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import { fire } from "../../firebase/firebase";

class SocialToolbar extends Component {
  render() {
    const {
      handleUploadSuccess,
      handleUploadError,
      handleUploadStart,
      handleProgress
    } = this.props;
    return (
      <div className="social-toolbar">
        <CustomUploadButton
          className="button is-primary"
          accept="image/*"
          storageRef={fire.storage().ref("images")}
          onUploadStart={handleUploadStart()}
          onUploadError={handleUploadError()}
          onUploadSuccess={handleUploadSuccess()}
          onProgress={handleProgress()}
        >
          Add Image
        </CustomUploadButton>
      </div>
    );
  }
}

export default SocialToolbar;
