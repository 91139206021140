import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Workspace from "./Workspace";
import CalculatorPanel from "./CalculatorPanel";
import LoginPrompt from "./LoginPrompt";

class Editor extends Component {
  renderEditor() {
    const {
      phases,
      config,
      addPhaseToConfig,
      changeProjectBuffer,
      addItemToPhase,
      handleHourChange,
      removeItemFromPhase,
      removePhaseFromConfig,
      editPhaseTitle,
      editPhaseRate,
      admin,
      displayName,
      submitMessage
    } = this.props;

    if (admin) {
      return (
        <Fragment>
          <CalculatorPanel
            changeProjectBuffer={changeProjectBuffer}
            config={config}
          />
          <Workspace
            submitMessage={submitMessage}
            editPhaseRate={editPhaseRate}
            editPhaseTitle={editPhaseTitle}
            removeItemFromPhase={removeItemFromPhase}
            addItemToPhase={addItemToPhase}
            handleHourChange={handleHourChange}
            config={config}
            removePhaseFromConfig={removePhaseFromConfig}
            addPhaseToConfig={addPhaseToConfig}
            phases={phases}
            displayName={displayName}
          />
        </Fragment>
      );
    } else {
      return <LoginPrompt />;
    }
  }
  render() {
    return <div className="editor">{this.renderEditor()}</div>;
  }
}

export default Editor;
