import React, { Component } from "react";
import _ from "lodash";
class CalculatorPanel extends Component {
  getPhaseHours = phase => {
    let hours = 0;
    //eslint-disable-next-line
    const phaseTotal = _.map(phase.defaultOptions, (phase, i) => {
      hours = phase.hours + hours;
    });
    return hours;
  };

  renderProjectHours = config => {
    let totalHours = 0;
    //eslint-disable-next-line
    const getPhaseHours = _.map(config.phases, (phase, i) => {
      _.map(phase.defaultOptions, option => {
        totalHours = totalHours + option.hours;
      });
    });
    return totalHours;
  };

  renderProjectTotal = subTotal => {
    let total = 0;
    //eslint-disable-next-line
    const addSubTotals = _.map(subTotal, price => {
      total = price + total;
    });

    return total;
  };
  render() {
    const { config } = this.props;

    const proj = _.filter(config.phases, phase => {
      if (phase.title === "Project Management") {
        return phase.title;
      }
    });

    if (proj[0]) {
      let projectMgmtHours = this.getPhaseHours(proj[0]);
      let projectMgmtCost = projectMgmtHours * proj[0].rate;
      // get total hours
      let totalHours = this.renderProjectHours(config).toFixed(2);

      let subTotalCost = [];

      return (
        <div className="calculator-panel">
          {/* Phase Containers */}
          <div className="container">
            <div className="top-labels">
              <div>Phase</div>
              <div>Hrs.</div>
              <div>Subtotal</div>
            </div>
            <div className="phases">
              {_.map(config.phases, (phase, i) => {
                const subTotal = this.getPhaseHours(phase) * phase.rate,
                  percentageOfProject =
                    this.getPhaseHours(phase) / (totalHours - projectMgmtHours);
                let buffer = 0;
                if (config.buffer !== undefined) {
                  console.log(config.buffer);
                  buffer = config.buffer / 100;
                }
                const total =
                  (subTotal + projectMgmtCost * percentageOfProject) *
                  (1 + buffer);

                if (phase.title !== "Project Management") {
                  subTotalCost.push(total);

                  return (
                    <div key={i} className="phase-box">
                      <div className="type">{phase.title}</div>
                      <div className="hours">{this.getPhaseHours(phase)}</div>
                      <div className="subtotal">${total.toFixed(2)}</div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="bottom-labels">
            {/* Project Management Container */}
            <div className="bottom-container">
              <div className="flex-container">
                <div className="buffer-title">Project Mgmt.</div>
                <div className="buffer-amount">
                  $ {projectMgmtHours * proj[0].rate} ({projectMgmtHours} hrs.)
                  {/* Project Management Totals */}
                </div>
              </div>
            </div>

            {/* Buffer Container */}

            <div className="bottom-container">
              <div className="flex-container">
                <div className="buffer-title">Buffer</div>
                <div className="buffer-amount">
                  <input
                    onChange={this.props.changeProjectBuffer()}
                    min={1}
                    value={config.buffer}
                    defaultValue={0}
                    type="number"
                  />
                  %
                </div>
              </div>
            </div>

            {/* Project Total */}

            <div className="bottom-container">
              <div className="flex-container">
                <div className="total">Project Total</div>
                <div className="project-total">
                  <span>$</span>
                  {this.renderProjectTotal(subTotalCost).toFixed(2)}
                  <span>({totalHours}hrs.)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return false;
  }
}

export default CalculatorPanel;
