import React, { Component } from "react";

import SignUpForm from "./SignUpForm";
import LogForm from "./LogForm";

export default class AuthForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAccount: false,
      isNew: false
    };
  }

  setAccount = () => e => {
    this.setState({
      hasAccount: true,
      isNew: true
    });
  };

  render() {
    const hasAccount = this.state.hasAccount;
    return (
      <div>
        {hasAccount ? (
          <div
            className="contact-form "
            style={{
              marginTop: this.props.marginTop,
              marginBottom: this.props.marginBottom,
              boxShadow: "none"
            }}
          >
            <div className="columns">
              <div className="form-container login-form">
                <div className="columns chooser">
                  <div
                    onClick={() => {
                      this.setState({ hasAccount: false });
                    }}
                    className="column inactive-auth"
                  >
                    <h2 className="has-text-bold">Sign Up</h2>
                  </div>
                  <div className="column active-auth">
                    <h2 className="has-text-bold">Login</h2>
                  </div>
                </div>
                <LogForm
                  isNew={this.state.isNew}
                  login={this.props.login}
                  location={this.props.location}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="contact-form "
            style={{
              marginTop: this.props.marginTop,
              marginBottom: this.props.marginBottom,
              boxShadow: "none"
            }}
          >
            <div className="columns">
              <div className="form-container login-form">
                <div className="columns chooser">
                  <div className="column active-auth">
                    <h2 className="has-text-bold">Sign Up</h2>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ hasAccount: true });
                    }}
                    className="column inactive-auth"
                  >
                    <h2 className="has-text-bold">Login</h2>
                  </div>
                </div>
                <SignUpForm
                  setAccount={this.setAccount()}
                  location={this.props.location}
                  login={this.props.login}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
